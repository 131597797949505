import { Card, Checkbox, Col, Input, Row, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DCFOutput } from "reia-dcf-client";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../../utils/digitHelper";
import {
  getAccCostsTerminalValue,
  getPerSpaceValue,
  getTerminalRentTypeFromI18nKey,
  getYeildProfileAtSaleCalculations,
  getYeildProfileCalculations
} from "../../../../../../../../utils/dcfHelper";
import IMAGES from "../../../../../../../../assets/images";
import { Switch } from 'antd';
import { getAgentCostsOrDefault, getDefaultAccCostsValuesUI } from "../../../../../../../../utils/dcfParamsHelper";
import { AssetDto } from "reia-rest-client";

function VeAssetValue({ allDataLoaded }) {

  const calculation = useSelector((state) => state.calculation);
  const { assetKPIs, calculationDetail } = calculation;

  const [isSwitch, setIsSwitch] = useState(false);
  const switchChange = (checked) => {
    setIsSwitch(checked);
  };

  const assetValuePSQM = isSwitch ?
    assetKPIs?.netTerminalAssetValueSqm
    : assetKPIs?.netAssetValueSqm;

  const unsavedValues = !calculation?.autoSaveCalc && calculation?.assetValueMismatched
  console.log(assetKPIs,'assetKPIs')
  return (
    <div>
      <Card bordered={false} className="assets_card">
        {/* <div className="d-flex justify-content-between assets_card__header mb-2"> */}
        <Row className="assets_card__header mb-2">
          <Col xs={24} sm={8}>
            <h3 className="text-black d-flex align-items-center" style={{ gap: '10px' }}>
              <Tooltip title={isSwitch ? "Asset Value" : "Exit Value"} color="#00215b" key="#00215b">
                <Switch
                  checked={isSwitch}
                  onChange={switchChange}
                />
              </Tooltip>
              <span>{isSwitch ? "Exit" : "Asset"} Value</span>
            </h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-center">
            <h3 className="text-black">
              {getFormattedEnglishNumber(assetValuePSQM)} € /sqm
            </h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-end">
            {/* <h3 className="text-black">{getFormattedEnglishNumber(newNetAssetValue)} €</h3> */}
            <h3 className="text-black">{isSwitch ? getFormattedEnglishNumber(assetKPIs?.netTerminalAssetValue) : getFormattedEnglishNumber(assetKPIs?.netAssetValue)} €</h3>
          </Col>
        </Row>
        {/* </div> */}
        <div style={{ borderBottom: '1px solid #B0BACD', paddingTop: unsavedValues ? "5px" : '12px', paddingBottom: unsavedValues ? "12px" : '15px' }}>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text">Gross Value {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Acquisition Costs {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Net Value {isSwitch ? "@ Exit" : ""}</h6>
            </div>
            {unsavedValues &&
              <div className="d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#E6E9EF", flexBasis: '36%', padding: "4px 12px", border: '.5px dashed #8A99B4', borderRadius: '4px' }}
              >
                <div>
                  <img src={IMAGES.DANGER} alt="" />
                  <p className="mb-0" style={{ color: '#FF7228' }}>Current </p>
                  <p className="mb-0" style={{ color: '#FF7228' }}>Changes</p>
                </div>
                <div>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(calculationDetail?.assetKPIs?.grossTerminalAssetValue) : getFormattedEnglishNumber(calculationDetail?.assetKPIs?.grossAssetValue)}</h6>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(calculationDetail?.assetKPIs?.landTransferExitValue + calculationDetail?.assetKPIs?.agentCostsExitValue + calculationDetail?.assetKPIs?.notaryCostsExitValue) : getFormattedEnglishNumber(calculationDetail?.assetKPIs?.landTransferValue + calculationDetail?.assetKPIs?.agentCostsValue + calculationDetail?.assetKPIs?.notaryCostsValue)}</h6>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(calculationDetail?.assetKPIs?.netTerminalAssetValue) : getFormattedEnglishNumber(calculationDetail?.assetKPIs?.netAssetValue)}</h6>
                </div>
              </div>
            }
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(assetKPIs?.grossTerminalAssetValue) : getFormattedEnglishNumber(assetKPIs?.grossAssetValue)} €</h6>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(assetKPIs?.landTransferExitValue + assetKPIs?.agentCostsExitValue + assetKPIs?.notaryCostsExitValue) : getFormattedEnglishNumber(assetKPIs?.landTransferValue + assetKPIs?.agentCostsValue + assetKPIs?.notaryCostsValue)} €</h6>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(assetKPIs?.netTerminalAssetValue) : getFormattedEnglishNumber(assetKPIs?.netAssetValue)} €</h6>
            </div>
          </div>
        </div>
        {
          isSwitch ?
            // <div
            //   className="pt-3"
            //   style={{
            //     display: "flex",
            //     gap: "20px",
            //     alignItems: "end",
            //     justifyContent: "space-between",
            //     paddingRight: "5px",
            //   }}
            // >
            <Row className="pt-3" style={{}}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile @ Exit
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={8} className="d-flex justify-content-center">
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalCurrentRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalCurrentRent * 100)} </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalCurrentRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalPotentialRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}> {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalPotentialRent * 100)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalPotentialRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalMarketRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalMarketRent * 100)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalMarketRent)}</div></div>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={8} className="d-flex justify-content-end" style={{ gap: '10px', flexWrap: 'wrap' }}>
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingLeveragedIRR ? assetKPIs?.financingLeveragedIRR * 100 : assetKPIs?.financingLeveragedIRR) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC  in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingCashOnCash ? assetKPIs?.financingCashOnCash * 100 : assetKPIs?.financingCashOnCash) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingEquityMultiple ? assetKPIs?.financingEquityMultiple * 100 : assetKPIs?.financingEquityMultiple) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col xs={24} sm={8} className="d-flex justify-content-end">
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <Typography>IRR</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "10px" }}>
                    <Typography>CoC</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "17px" }}>
                    <Typography>EM</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* </div> */}
            </Row>
            :
            // <div
            //   className="pt-3"
            //   style={{
            //     display: "flex",
            //     gap: "20px",
            //     alignItems: "end",
            //     justifyContent: "space-between",
            //     paddingRight: "5px",
            //   }}
            // >
            <Row Row className="pt-3" style={{}}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={8} className="d-flex justify-content-center">
                {/* <Row> */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldCurrentRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.grossInitialYieldCurrentRent * 100)} </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldCurrentRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldPotentialRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}> {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldPotentialRent * 100)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldPotentialRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldMarketRent * 100)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.grossInitialYieldMarketRent * 100)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldMarketRent)}</div></div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="d-flex justify-content-end">
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingLeveragedIRR ? assetKPIs?.financingLeveragedIRR * 100 : assetKPIs?.financingLeveragedIRR) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingCashOnCash ? assetKPIs?.financingCashOnCash * 100 : assetKPIs?.financingCashOnCash) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        {getFormattedDecimalNumber(assetKPIs?.financingEquityMultiple ? assetKPIs?.financingEquityMultiple * 100 : assetKPIs?.financingEquityMultiple) ?? 'NA'}
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                </div>
              </Col>
              {/* </Row> */}
              {/* <Col xs={24} sm={8} className="d-flex justify-content-end">
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <Typography>IRR</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "10px" }}>
                    <Typography>CoC</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "17px" }}>
                    <Typography>EM</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* </div> */}
            </Row>
        }
      </Card >
    </div >
  );
}

export default VeAssetValue;

const styles = {
  percentageBox: {
    background: "#E6E9EF",
    borderRadius: "4px",
    border: '.5px solid #8A99B4',
    // padding: '2px 15px'
    display: 'flex',
    height: '1.5rem',
    width: '4.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  }
}