import { Card, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RentRollAssumptions } from "reia-rest-client";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../../../../utils/digitHelper";
import FormattedInputNumber from "../../../../../../../../components/Layout/FormattedInputNumber";

function VeAssumptionsParameter() {
  const dispatch = useDispatch();
  const { calculationDetail } = useSelector((state) => state.calculation);
  const persistedRentRollAssumptions: [string, RentRollAssumptions] = calculationDetail ? calculationDetail.rentRollsAssumptions ? calculationDetail.rentRollsAssumptions : {} : null;
  const { rentRollAssumptions } = useSelector((state) => state.calculation);

  const getRentRollAssumptionValue = (i18nUseTypeId: number, paramName: string, rentRollAssumption: RentRollAssumptions) => {
    if (rentRollAssumptions.hasOwnProperty(i18nUseTypeId) && rentRollAssumptions[i18nUseTypeId].hasOwnProperty(paramName)) {
      return rentRollAssumptions[i18nUseTypeId][paramName]
    }
    else {
      return rentRollAssumption[paramName]
    }
  }

  const setRentRollAssumptionValue = (i18nUseTypeId: number, paramName: string, paramValue: any) => {
    const tempRentRollAssumptions = JSON.parse(JSON.stringify(rentRollAssumptions));
    let origValue;

    if (persistedRentRollAssumptions.hasOwnProperty(i18nUseTypeId)) {
      origValue = persistedRentRollAssumptions[i18nUseTypeId][paramName]
    }

    // if value original remove from temp rentrollassumptions
    if (origValue === paramValue) {
      if (tempRentRollAssumptions.hasOwnProperty(i18nUseTypeId)) {
        delete tempRentRollAssumptions[i18nUseTypeId][paramName]

        if (Object.keys(tempRentRollAssumptions[i18nUseTypeId]).length === 0) {
          delete tempRentRollAssumptions[i18nUseTypeId]
        }
        setRentRollAssumption(tempRentRollAssumptions);
      }

    } else {
      if (!tempRentRollAssumptions.hasOwnProperty(i18nUseTypeId)) {
        tempRentRollAssumptions[i18nUseTypeId] = {}
      }
      tempRentRollAssumptions[i18nUseTypeId][paramName] = paramValue

      setRentRollAssumption(tempRentRollAssumptions);
    }

  }

  const setRentRollAssumption = (rentRollAssumptions) => {
    dispatch({ type: "calculation/setRentRollAssumption", payload: rentRollAssumptions })
  }

  return (
    <div className="assumptionsParameter">
      <Card bordered={false} className="assets_card">
        <div className="assets_card__header">
          <h3 className="text-black">Assumptions / Parameter</h3>

        </div>
        <Row gutter={24}>
          <Col xs={6} className="d-flex flex-column" style={{ rowGap: "14px" }}>
            <Row gutter={24} className="align-items-center mt-4 mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-white">
                  EMPTY
                </h4>
              </Col>
            </Row>
            <Row gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-gray assumption_text">
                  Market Rent/ €sq m or unit/month
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-gray assumption_text">
                  Initial Renewal Probability in %
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-gray assumption_text">Contiunuing Renewal Probability</h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-gray assumption_text">
                  Lease Duration
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 text-gray assumption_text">
                  Lease Duration - open end contract
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mt-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="text-black mb-0 assumption_heading">
                  Reletting Costs
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Void period - initial in month
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Void period - continuing in month
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Vacancy Costs €/sq m/year
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Initial Tenant Improvements (TI&apos;s)
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Continuing Tenant Improvements (TI&apos;s)
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Agent Costs in month
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mt-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="text-black mb-0 assumption_heading">
                  Non Recoverable Costs Term
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Maintenance €/sq m or unit m/year
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Managment % of Market Rent
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Other Costs % of Markets Rent
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mt-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="text-black mb-0 assumption_heading">
                  Non Recoverable Costs Reletting
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Maintenance €/sq m or unit m/year
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Managment % of Market Rent
                </h4>
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              <Col xs={24} className="d-flex align-items-center">
                <h4 className="mb-0 assumption_text">
                  Other Costs % of Markets Rent
                </h4>
              </Col>
            </Row>
          </Col>
          <Col xs={18} className="custom-scroll" style={{ width: '100%', overflowX: 'auto' }}>
            <Row wrap={false} gutter={24} className="align-items-center mt-4 mb-2 ">
              {Object.entries(persistedRentRollAssumptions).map(entry => <Col xs={5}>
                <span className="input-headings d-flex justify-content-center">
                  {entry[1].useType.translations.en} ({entry[1].rentRollsId.length})
                </span>
              </Col>)}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const marketRentPerSqmAvg = getRentRollAssumptionValue(entry[1].useType.id, "marketRentPerSqmAvg", entry[1])
                return <Col xs={5}>
                  <FormattedInputNumber value={marketRentPerSqmAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => {
                      setRentRollAssumptionValue(entry[1].useType.id, "marketRentPerSqmAvg", e.target.value)
                    }}
                    className="assumptions-input-filed removeStyles addon-right"
                    addonAfter="€"
                  />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const initialRenewalProbabilityAvg = getRentRollAssumptionValue(entry[1].useType.id, "initialRenewalProbabilityAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(initialRenewalProbabilityAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "initialRenewalProbabilityAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="%" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const continuingRenewalProbabilityAvg = getRentRollAssumptionValue(entry[1].useType.id, "continuingRenewalProbabilityAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(continuingRenewalProbabilityAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "continuingRenewalProbabilityAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="%" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const leaseDurationInMonthsAvg = getRentRollAssumptionValue(entry[1].useType.id, "leaseDurationInMonthsAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(leaseDurationInMonthsAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "leaseDurationInMonthsAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="m" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const leaseDurationOpenEndInMonthsAvg = getRentRollAssumptionValue(entry[1].useType.id, "leaseDurationOpenEndInMonthsAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(leaseDurationOpenEndInMonthsAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "leaseDurationOpenEndInMonthsAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="m" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mt-4">
              <Col xs={9} className="d-flex align-items-center">
                {/* <h4 className="font-weight-bold text-white mb-0">
                    Empty
                  </h4> */}
              </Col>
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const initialVoidPeriodAvg = getRentRollAssumptionValue(entry[1].useType.id, "initialVoidPeriodAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(initialVoidPeriodAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "initialVoidPeriodAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="m" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const continuingVoidPeriodAvg = getRentRollAssumptionValue(entry[1].useType.id, "continuingVoidPeriodAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(continuingVoidPeriodAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "continuingVoidPeriodAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="m" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const vacancyCostsAvg = getRentRollAssumptionValue(entry[1].useType.id, "vacancyCostsAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(vacancyCostsAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "vacancyCostsAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="€" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const relettingCostsInitialTIsAvg = getRentRollAssumptionValue(entry[1].useType.id, "relettingCostsInitialTIsAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(relettingCostsInitialTIsAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "relettingCostsInitialTIsAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="€" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const relettingCostsContinuingTIsAvg = getRentRollAssumptionValue(entry[1].useType.id, "relettingCostsContinuingTIsAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(relettingCostsContinuingTIsAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "relettingCostsContinuingTIsAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="€" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const relettingCostsAgentAvg = getRentRollAssumptionValue(entry[1].useType.id, "relettingCostsAgentAvg", entry[1])
                return <Col xs={5}>
                  <Input value={getFormattedEnglishNumber(relettingCostsAgentAvg)}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "relettingCostsAgentAvg", e.target.value) }}
                    className="assumptions-input-filed"
                    addonAfter="m" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center pt-1 pb-1">
              {Object.entries(persistedRentRollAssumptions).map(entry => <Col
                xs={5}
                className="d-flex justify-content-between align-items-center text-black"
              >
                <span className="text-white">Term</span>
                {/* <span className="pr-2">Reletting</span> */}
              </Col>)}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrctMaintenanceAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrctMaintenanceAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrctMaintenanceAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrctMaintenanceAvg", e.target.value) }}
                    className="term-input top-left-input removeStyles addon-right"
                    addonAfter="€"  style={{width:"100%"}}/>
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrctManagementAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrctManagementAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrctManagementAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrctManagementAvg", e.target.value) }}
                    className="term-input top-left-input removeStyles addon-right"
                    addonAfter="%" style={{width:"100%"}} />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrctOtherCostsAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrctOtherCostsAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrctOtherCostsAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrctOtherCostsAvg", e.target.value) }}
                    className="term-input top-left-input removeStyles addon-right"
                    addonAfter="%" style={{width:"100%"}} />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center pt-1 pb-1">
              {Object.entries(persistedRentRollAssumptions).map(entry => <Col
                xs={5}
                className="d-flex justify-content-between align-items-center text-black"
              >
                {/* <span>Term</span> */}
                <span className="pr-2 text-white">Reletting</span>
              </Col>)}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrcrMaintenanceAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrcrMaintenanceAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrcrMaintenanceAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrcrMaintenanceAvg", e.target.value) }}
                    className="reletting-input top-right-input removeStyles addon-right"
                    addonAfter="€"  style={{width:"100%"}}/>
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrcrManagementAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrcrManagementAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrcrManagementAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrcrManagementAvg", e.target.value) }}
                    className="reletting-input top-right-input removeStyles addon-right"
                    style={{width:"100%"}}
                    addonAfter="%" />
                </Col>
              })}
            </Row>
            <Row wrap={false} gutter={24} className="align-items-center mb-2">
              {Object.entries(persistedRentRollAssumptions).map(entry => {
                const nrcrOtherCostsAvg = getRentRollAssumptionValue(entry[1].useType.id, "nrcrOtherCostsAvg", entry[1])
                return <Col xs={5} className="d-flex" style={{ gap: '9px' }}>
                  <FormattedInputNumber value={nrcrOtherCostsAvg} formatter={getFormattedDecimalNumber}
                    onChange={(e) => { setRentRollAssumptionValue(entry[1].useType.id, "nrcrOtherCostsAvg", e.target.value) }}
                    className="reletting-input top-right-input removeStyles addon-right"
                    addonAfter="%"  style={{width:"100%"}} />
                </Col>
              })}
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VeAssumptionsParameter;
